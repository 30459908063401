import React from "react";
import "./EpochProgress.css";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FaLock } from 'react-icons/fa';
import { usePoolsClosed } from "../../contexts/PoolsClosedContext";
import CountdownTimer  from "../CountdownTimer/CountdownTimer";

function EpochProgress(props) {
    const { poolsClosed, setPoolsClosed } = usePoolsClosed();    
    const { progress, epochs, epoch } = props;
    console.log('poolsClosed', poolsClosed);
    const contractEpocData = epoch;

    const getActiveEpochLabel = (progress) => {
        const activeEpoch = epochs.epochs.find((epoch) => epoch.isActive(progress));
        return activeEpoch ? `${activeEpoch.name}` : "";
    };

    const epochLabels = epochs.epochs.map((epoch, index) => {
      const isActive = epoch.isActive(progress);
      const label = isActive ? getActiveEpochLabel(progress) : epoch.name;
      return (
        <div key={index} className="EpochProgress-label locks">
          {label}
          {epoch.name === 'Unlocked' ? (
            <>
                <div className="EpochProgress-label-progress" />
                {contractEpocData && new Date((contractEpocData.endTime - ((contractEpocData['_length'] / 3) * 2)) * 1000) > new Date() && <CountdownTimer targetTimestamp={new Date((contractEpocData.endTime - ((contractEpocData['_length'] / 3) * 2)) * 1000)} />}
            </>
        ) : (
            <>
            <div className="EpochProgress-label-progress ">
                <FaLock />
            </div>
            <div className="EpochProgress-label-progress">
                {contractEpocData && new Date(contractEpocData.endTime * 1000) > new Date() && <CountdownTimer targetTimestamp={new Date(contractEpocData.endTime * 1000)} />}
            </div>
            </>
        )}
        </div>
      );
    });

    return (
        <div className="EpochProgress">
            <div className="EpochProgress-bar">
                <div className={contractEpocData && new Date(contractEpocData.endTime * 1000) < new Date() ? "EpochProgress-bar-inner-full" : "EpochProgress-bar-inner"} style={{ width: `${progress}%` }}></div>
                <div className="EpochProgress-labels">
                    {epochLabels}
                </div>
            </div>
        </div>
    );
    // return (
    //     <div className="EpochProgress">
    //         <div className="EpochProgress-bar">
    //             <div className="EpochProgress-bar-inner" style={{ width: `${progress}%` }}></div>
    //             <div className="EpochProgress-labels">
    //                 <div className="EpochProgress-label">
    //                     {epochs.one}
    //                     {progress <= 33 && <div className="EpochProgress-label-progress">{progress}%</div>}
    //                 </div>

    //                 <div className="EpochProgress-label">
    //                     {epochs.two}
    //                     {progress > 33 && progress <= 66 && <div className="EpochProgress-label-progress">{progress}%</div>}
    //                 </div>

    //                 <div className="EpochProgress-label">
    //                     {epochs.three}
    //                     {progress > 66 ? (
    //                         <div className="EpochProgress-label-progress">
                                
    //                         </div>
    //                     ) : (
    //                         <div className="EpochProgress-label-progress">
    //                             {/* Locked icon */}
    //                         </div>
    //                     )}
    //                 </div>

    //             </div>
    //         </div>
    //     </div>
    // );
}

export default EpochProgress;
