// TxnPendingContext.js
import { createContext, useContext, useState } from 'react';

const TxnPendingContext = createContext();

export const useTxnPending = () => {
  return useContext(TxnPendingContext);
};

export const TxnPendingProvider = ({ children }) => {
  const [txnPending, setTxnPending] = useState(false);

  return (
    <TxnPendingContext.Provider value={{ txnPending, setTxnPending }}>
      {children}
    </TxnPendingContext.Provider>
  );
};
