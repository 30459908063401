// TxnPendingContext.js
import { createContext, useContext, useState } from 'react';

const PoolsClosedContext = createContext();

export const usePoolsClosed = () => {
  return useContext(PoolsClosedContext);
};

export const PoolsClosedProvider = ({ children }) => {
  const [poolsClosed, setPoolsClosed] = useState(false);

  return (
    <PoolsClosedContext.Provider value={{ poolsClosed, setPoolsClosed }}>
      {children}
    </PoolsClosedContext.Provider>
  );
};
