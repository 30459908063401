import React, { useState, useEffect } from 'react';

function CountdownTimer({ targetTimestamp }) {
  const calculateTimeRemaining = () => {
    const remaining = targetTimestamp - new Date().getTime();
    return remaining > 0 ? remaining : 0;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetTimestamp]);

  function padToTwo(number) {
    if (number<=99) { number = ("0"+number).slice(-2); }
    return number;
  }

  const remainingHours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const remainingMinutes = Math.floor(
    (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
  );
  const remainingSeconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return (
    <div>
      {padToTwo(remainingHours)}:{padToTwo(remainingMinutes)}:{padToTwo(remainingSeconds)}
    </div>
  );
}

export default CountdownTimer;
